// IoTab colors

// primary

$light-primary: #00aff0;
$light-primary-contrast: #ffffff;

$dark-primary: #00aff0;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #212121;
$light-accent-contrast: #ffffff;

$dark-accent: #212121;
$dark-accent-contrast: #ffffff;

// common

$page-background: #00aff02f;
